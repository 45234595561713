import { DynamicComponentFragment } from 'graphql/components/GetComponents.generated';
import pipe from 'lodash/fp/pipe';
import isEmpty from 'lodash/fp/isEmpty';
import omit from 'lodash/fp/omit';
import { PageLead } from 'components/core/page-lead';
import { HeroMediaGallery } from 'components/domain/hero-media-gallery';
import { RawTextBlock } from '@hotelplan/components.common.raw-text-block';
import { TextBlock } from '@hotelplan/components.common.text-block';
import styled from 'styled-components';
import Accordion from 'components/domain/accordion/Accordion';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';
import CommonMediaGallery from './CommonMediaGallery';
import { ImageTextComponent } from './ImageTextBlock';
import LinksListView from './links-list-view';
import React from 'react';
import { RecommendationsGroup } from 'components/domain/recommendations';
import MultiAccordion from 'components/domain/multi-accordion/MultiAccordion';
import { DisrupterObject } from '@hotelplan/graphql.types';

interface IDynamicComponentsProps {
  components?: DynamicComponentFragment[];
  disrupterObject?: DisrupterObject;
}

const isObjectEmpty = pipe(omit(`__typename`), isEmpty);

const MediaGalleryWrap = styled.div(
  sx2CssThemeFn({
    mb: [3, 4],
  })
);

const BackgroundWrap = styled.div<{
  backgroundColor?: string;
  backgroundImage?: string;
  fontColor?: string;
}>(
  ({ backgroundColor, backgroundImage, fontColor }) => {
    return {
      backgroundColor: backgroundImage ? null : backgroundColor,
      backgroundImage: backgroundImage ? `url(${backgroundImage})` : null,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      color: fontColor,
    };
  },
  sx2CssThemeFn({
    '.markdown-text': {
      table: {
        borderCollapse: 'collapse',
        marginBottom: '20px',
        width: ['100%', 'auto'],
      },
      tr: {
        borderBottom: '1pt solid #666',
      },
      'td, th': {
        padding: '6px 30px 6px 0',
        textAlign: 'left',
      },
    },
  })
);

const DynamicComponents: React.FC<IDynamicComponentsProps> = ({
  disrupterObject,
  components,
}: IDynamicComponentsProps) => {
  return (
    <>
      {components?.map((component, index) => {
        if (isObjectEmpty(component)) return null;
        switch (component.__typename) {
          case 'TextComponent':
            const imgUrl = component?.backgroundImage?.resized.filter(
              image => image.id === '1x_desktop'
            )[0]?.url;

            return (
              <BackgroundWrap
                key={`dc-${index}`}
                backgroundColor={component.backgroundColor}
                backgroundImage={imgUrl}
                fontColor={component.fontColor}
              >
                {component.type === 'raw' ? (
                  <RawTextBlock
                    uid={`dc-${index}`}
                    title={component.title}
                    text={component.text}
                    className="text-block raw-text"
                  />
                ) : (
                  <TextBlock
                    id={`dc-${index}`}
                    title={component.title}
                    text={component.text}
                    className="text-block markdown-text"
                  />
                )}
              </BackgroundWrap>
            );
          case 'HeroMediaGallery': {
            return (
              <PageLead
                key={`heroes-${index}`}
                title={component.mainTitle}
                text={component.mainText}
                colorTitle={component.fontColor}
                colorText={component.mainTextFontColor}
                disrupterObject={disrupterObject}
                mediaGallery={
                  <HeroMediaGallery
                    items={component.mediaItems || []}
                    ratio={0}
                  />
                }
              />
            );
          }
          case 'FaqComponent': {
            return (
              <Accordion items={component.items} key={`Accordion-${index}`} />
            );
          }
          case 'ImageTextComponent': {
            return (
              <ImageTextComponent
                key={`ImageTextComponent-${index}`}
                {...component}
              />
            );
          }
          case 'MediaGallery': {
            return (
              <MediaGalleryWrap key={`MediaGalleryWrap-${index}`}>
                <CommonMediaGallery items={component.mediaItems} />
              </MediaGalleryWrap>
            );
          }
          case 'LinkListComponent': {
            return (
              <LinksListView
                component={component}
                key={`LinksListView-${index}`}
                index={index}
              />
            );
          }
          case 'MarketingRecommendation':
          case 'ThemeRecommendation':
          case 'ProductRecommendation':
          case 'BD4TravelRecommendationGroup':
          case 'GeoRecommendation': {
            return (
              <RecommendationsGroup
                key={`recommendation-group-${index}`}
                component={component}
              />
            );
          }
          case 'AccordionComponent': {
            return (
              <MultiAccordion
                items={component}
                key={`accordion-component-${index}`}
              />
            );
          }
        }
      })}
    </>
  );
};

export default DynamicComponents;
