import { createGetMoveState, ICarouselState } from './createGetMoveState';

interface ISliderState {
  i: number;
  targetIndex: number;
  dir: number;
  isPrevSlide: boolean;
  isCurrentSlide: boolean;
  isTargetSlide: boolean; // semi-visible target slide on dragging
  isActive: boolean; // is in viewport
  baseX: number; // current x state for slide
}

interface IGetSliderStateFn {
  (s: ICarouselState, i: number, mx?: number): ISliderState;
}

export function createGetSliderState(
  count: number,
  width: number
): IGetSliderStateFn {
  const getMove = createGetMoveState(count);

  return (s, i, mx = 0) => {
    const { dir, targetIndex } = getMove(s, mx);
    const isCurrentSlide = i === s.curr;
    const isPrevSlide = !isCurrentSlide && i === s.prev;
    const isTargetSlide = i === targetIndex;
    const isActive = [s.prev, s.curr].includes(i) || isTargetSlide;

    return {
      i,
      targetIndex,
      dir,
      isPrevSlide,
      isCurrentSlide,
      isTargetSlide,
      isActive,
      baseX: isTargetSlide
        ? dir * width
        : isPrevSlide
        ? -dir * width
        : (i - s.curr) * width,
    };
  };
}
