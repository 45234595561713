import { useTranslation } from 'next-i18next';
import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { TravelType } from '@hotelplan/graphql.types';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';
import { formatPrice } from '@hotelplan/libs.utils';
import { Card } from 'components/domain/card';
import { Disrupter, EDisrupterPlacement } from 'components/domain/disrupter';
import { RecommendationSectionTitle } from 'components/domain/recommendation-list';
import { RecommendationSlider } from 'components/domain/recommendations/components/Slider';
import { ThemeRecommendationFragment } from 'graphql/recommendations/ThemeRecommendation.generated';
import { mapDurationToWeeks } from 'libs';
import ThemeRecommendationItemSlideInfo from './ThemeRecommedationItemSlideInfo';
import { RecommendationWrap } from './ThemeRecs.styled';
import { mapThemeRecommendationItemToMediaGalleryItem } from './ThemeRecs.utils';

const ThemeRecommendationsWrap = styled.div(
  sx2CssThemeFn({
    mt: [null, 4],
  })
);

interface ISectionProps extends ThemeRecommendationFragment {
  className?: string;
  testId?: string;
}

export default function ThemeRecommendationsSection({
  items,
  className,
  testId,
  displayType = 'SWIPE',
  name,
}: ISectionProps): ReactElement {
  const [t] = useTranslation(['common', 'search']);
  const { mobile } = useDeviceType();

  const itemsResult = items.map(item => {
    const {
      destinationName,
      duration,
      travelType,
      id,
      name: title,
      price,
      image,
      link,
      disrupterObject,
    } = item;

    const cardText = `${t(
      'common:cheapestOffer'
    )} ${destinationName}, <br>${mapDurationToWeeks(duration, t)},
    ${
      travelType === TravelType.Package
        ? `<br> ${t('search:travel.type.package')}`
        : `<br> ${t('search:travel.type.hotel')}`
    }`;

    return (
      <Card
        key={id}
        cardTitle={
          <>
            <span className="card-title">{title}</span>
            {price && (
              <p className="card-subtitle">
                <span className="prefix">
                  {t('priceFrom')} {price.currency}
                </span>
                <span className="price">
                  {formatPrice(price, { currency: false })}
                </span>
              </p>
            )}
          </>
        }
        image={image}
        btnText={t('allOffers.btn')}
        btnIcon={{
          name: 'chevron-long-right',
          size: 'sm',
        }}
        link={link}
        cardText={cardText}
        disrupterObject={disrupterObject}
      />
    );
  });

  const newItems = mapThemeRecommendationItemToMediaGalleryItem(items);

  return (
    <ThemeRecommendationsWrap className={className} data-id={testId}>
      {name ? (
        <RecommendationSectionTitle>{name}</RecommendationSectionTitle>
      ) : null}

      {displayType === 'SWIPE' && !mobile ? (
        <RecommendationSlider items={newItems}>
          {slide => (
            <>
              {items[slide].disrupterObject ? (
                <Disrupter
                  {...items[slide].disrupterObject}
                  placement={EDisrupterPlacement.SLIDER}
                />
              ) : null}
              <ThemeRecommendationItemSlideInfo
                item={items[slide]}
                slide={slide + 1}
                count={items.length}
              />
            </>
          )}
        </RecommendationSlider>
      ) : (
        <RecommendationWrap>{itemsResult}</RecommendationWrap>
      )}
    </ThemeRecommendationsWrap>
  );
}
