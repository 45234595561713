import { useTranslation } from 'next-i18next';
import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { GeoType, ProductType } from '@hotelplan/graphql.types';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';
import { formatPrice } from '@hotelplan/libs.utils';
import { Card } from 'components/domain/card';
import { RecommendationSectionTitle } from 'components/domain/recommendation-list';
import { ProductRecommendationFragment } from 'graphql/recommendations/ProductRecommendation.generated';
import { mapDurationToWeeks } from 'libs';
import { RecommendationWrap } from './ProductRecs.styled';

const ProductRecommendationsWrap = styled.div<{ isSingle?: boolean }>(
  ({ isSingle }) =>
    sx2CssThemeFn({
      mt: [3, 4],
      '.slider-control-centerleft, .slider-control-centerright': {
        display: isSingle ? 'none' : 'block',
      },
      '.content-text': {
        flexGrow: '1',
        'p:last-of-type': {
          maxWidth: '49%',
        },
      },
      '.card-subtitle': {
        fontSize: 1,
      },
    })
);

interface ISectionProps extends ProductRecommendationFragment {
  className?: string;
  testId?: string;
}

export default function ProductRecommendationsSection({
  items,
  className,
  testId,
  name,
}: ISectionProps): ReactElement {
  const [t] = useTranslation('common');

  const itemsResult = items.map(item => {
    const {
      geoBreadcrumbs,
      duration,
      mealType,
      offerId,
      name: mainTitle,
      price,
      image,
      link,
      disrupterObject,
      type,
    } = item;

    const destination =
      geoBreadcrumbs.find(geo => geo.type === GeoType.Destination)?.name ||
      geoBreadcrumbs.find(geo => geo.type === GeoType.Country)?.name;

    const weeksDuration = mapDurationToWeeks(duration, t);

    const offerTravelType = type
      ? type === ProductType.Package
        ? `${t('common:offer.travelType.package')}`
        : `${t('common:offer.travelType.hotel')}`
      : '';

    const cardText = `<p>${destination}, ${weeksDuration}</p><p>${offerTravelType}</p><p>${mealType}</p>`;

    return (
      <Card
        key={offerId}
        cardTitle={<span className="card-title">{mainTitle}</span>}
        image={image}
        btnText={
          <>
            {price && (
              <p className="card-subtitle">
                <span className="prefix">
                  {t('priceFrom')} {price.currency}
                </span>
                <span className="price">
                  {formatPrice(price, { currency: false })}
                </span>
              </p>
            )}
          </>
        }
        btnIcon={{
          name: 'chevron-long-right',
          size: 'sm',
        }}
        link={link}
        cardText={cardText}
        disrupterObject={disrupterObject}
      />
    );
  });

  return (
    <ProductRecommendationsWrap
      className={className}
      data-id={testId}
      isSingle={items.length === 1}
    >
      {name ? (
        <RecommendationSectionTitle>{name}</RecommendationSectionTitle>
      ) : null}
      <RecommendationWrap>{itemsResult}</RecommendationWrap>
    </ProductRecommendationsWrap>
  );
}
