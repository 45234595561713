import styled from 'styled-components';
import { Image } from '@hotelplan/components.common.image';

export const ImageMedia = styled(Image)({
  width: '100%',
  height: '100%',
  pointerEvents: 'none',
  alignSelf: 'center',
  objectFit: 'cover',
});
