import styled from 'styled-components';
import { Image, INormalizedImage } from '@hotelplan/components.common.image';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';

enum ImagePlacement {
  Center = 'CENTER',
  Left = 'LEFT',
  Right = 'RIGHT',
}

const ImageTextComponentWrapper = styled.p<{ imagePlacement: ImagePlacement }>(
  ({ theme, imagePlacement }) =>
    sx2CssThemeFn({
      display: 'flex',
      mx: [3, 0],
      flexDirection:
        imagePlacement !== 'RIGHT'
          ? ['column', 'row-reverse']
          : ['column', 'row'],
      alignItems: 'start',
      gap: [2, 4],
      '.image': {
        flex: 1,
        flexShrink: 0,
        img: {
          maxHeight: '100%',
          maxWidth: '100%',
          width: '100%',
        },
        '.image-description': {
          fontSize: ['14px', 0],
          fontStyle: 'italic',
        },
        mb: [4, 0],
      },
      '.text': {
        ...theme.text.richText,
        flex: 1,
        flexShrink: 0,
      },
    })
);

interface IImageTextComponent {
  text: string;
  image?: INormalizedImage;
  imagePlacement: any;
}

export const ImageTextComponent: React.FC<IImageTextComponent> = ({
  text,
  image,
  imagePlacement,
}) => {
  return (
    <ImageTextComponentWrapper
      data-id="image-text-component"
      imagePlacement={imagePlacement}
    >
      <p className="text" dangerouslySetInnerHTML={{ __html: text }} />
      <figure className="image">
        {image ? <Image {...image} /> : null}
        <figcaption className="image-description">{image.alt}</figcaption>
      </figure>
    </ImageTextComponentWrapper>
  );
};
