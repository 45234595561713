import { useTranslation } from 'next-i18next';
import React from 'react';
import { Card } from 'components/domain/card';
import { getFormattedOfferInfo } from 'components/domain/geo';
import {
  RecommendationList,
  RecommendationSectionTitle,
} from 'components/domain/recommendation-list';
import {
  EGeoRecsDisplayType,
  TGeoOffer,
} from 'components/domain/recommendations/types';
import { GeoRecommendationFragment } from 'graphql/recommendations/GeoRecommendation.generated';
import {
  getFormattedPrice,
  mapDurationToWeeks,
  mapTravelTypeToOfferTravelType,
} from 'libs';
import {
  GeoChildWrapper,
  GeoRecsLargeWrapper,
  GeoRecsWrapper,
} from './GeoRecs.styled';

export default function GeoRecsContainer({
  name,
  titleFontColor,
  displayType,
  items,
}: GeoRecommendationFragment): React.ReactElement {
  const [t] = useTranslation('common');

  return (
    <GeoRecsWrapper>
      {name ? (
        <RecommendationSectionTitle
          style={{
            color: titleFontColor,
          }}
        >
          {name}
        </RecommendationSectionTitle>
      ) : null}
      {
        {
          [EGeoRecsDisplayType.small]: (
            <RecommendationList>
              {items.map(function renderSmallGeoCard({
                destinationName,
                geoObject,
                image,
                perPersonPrice,
                travelType,
                duration,
                link,
              }) {
                const price = getFormattedPrice(
                  perPersonPrice,
                  t('common:priceFrom')
                );
                const formattedOfferInfo = getFormattedOfferInfo({
                  destinationName,
                  numberOfNightsInfo: mapDurationToWeeks(duration, t),
                  travelTypeInfo: mapTravelTypeToOfferTravelType(travelType, t),
                  example: t('common:cheapestOffer'),
                });
                const cardTitle = (
                  <>
                    <span className="card-title">{destinationName}</span>
                    <span>{price}</span>
                  </>
                );

                return (
                  <Card
                    key={geoObject.id}
                    cardTitle={cardTitle}
                    customCardText={formattedOfferInfo}
                    image={image}
                    link={link}
                    btnText={t('allOffers.btn')}
                    btnIcon={{
                      name: 'chevron-long-right',
                      size: 'sm',
                    }}
                  />
                );
              })}
            </RecommendationList>
          ),
          [EGeoRecsDisplayType.large]: (
            <GeoRecsLargeWrapper>
              {items.map(function renderLargeGeoCard(
                {
                  geoObject,
                  image,
                  count,
                  link,
                  leadText,
                  perPersonPrice,
                  travelType,
                  duration,
                },
                index
              ) {
                const offer: TGeoOffer = {
                  cheapestPrice: perPersonPrice,
                  travelTypeInfo: mapTravelTypeToOfferTravelType(travelType, t),
                  numberOfNightsInfo: mapDurationToWeeks(duration, t),
                };

                return (
                  <GeoChildWrapper
                    key={geoObject.id}
                    index={index}
                    name={geoObject.name}
                    image={image}
                    link={link}
                    productsTotal={count}
                    leadText={leadText}
                    offer={offer}
                  />
                );
              })}
            </GeoRecsLargeWrapper>
          ),
        }[displayType]
      }
    </GeoRecsWrapper>
  );
}
