import { IMediaGalleryItem } from '@hotelplan/components.common.responsive-media-gallery';
import { Price } from '@hotelplan/graphql.types';
import { Bd4recommendationFragment } from 'graphql/components/BD4RecommendationGroup.generated';
import { ExternalLinkFragment } from 'graphql/link/ExternalLink.generated';
import { InternalLinkFragmentFragment } from 'graphql/link/InternalLink.generated';
import { MarketingRecommendationItemFragment } from 'graphql/marketing/MarketingRecommendationItem.generated';
import { ProductRecommendationItemFragment } from 'graphql/orl/ProductRecommendationItem.generated';
import { GeoRecommendationItemFragment } from 'graphql/recommendations/GeoRecommendation.generated';
import { ThemeRecommendationItemFragment } from 'graphql/theme/ThemeRecommendationItem.generated';

export type TBaseRecommendationItems = IMediaGalleryItem & {
  mainText?: string;
  link?: InternalLinkFragmentFragment & ExternalLinkFragment;
};

export type RecommendationItemT =
  | MarketingRecommendationItemFragment
  | ThemeRecommendationItemFragment
  | Bd4recommendationFragment
  | GeoRecommendationItemFragment
  | ProductRecommendationItemFragment;

export enum EGeoRecsDisplayType {
  small = 'SMALL',
  large = 'LARGE',
}

export type TGeoOffer = {
  cheapestPrice?: Omit<Price, 'total'>;
  numberOfNightsInfo?: string;
  travelTypeInfo?: string;
};
