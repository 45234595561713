import React from 'react';
import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';
import Accordion from 'components/domain/accordion/Accordion';
import { AccordionComponentFragment } from 'graphql/fragments/Accordion.generated';

const MultiAccordionWrapper = styled.div(() =>
  sx2CssThemeFn({
    '.accordion': {
      mb: 3,
      mx: [-2, 0],
      py: 0,
    },
  })
);

const TextWrapper = styled.div<{
  backgroundColor: 'string';
  fontColor: 'string';
}>(({ backgroundColor, fontColor }) =>
  sx2CssThemeFn({
    backgroundColor: backgroundColor,
    mb: 2,
    mx: [3, 0],
    '.title': {
      color: fontColor,
      mb: [null, 2],
      fontSize: ['18px', '26px'],
    },
    '.text': {
      fontSize: ['14px', 0],
    },
  })
);

const MultiAccordion: React.FC<{ items: AccordionComponentFragment }> = ({
  items,
}) => {
  const {
    topMainTitle,
    topMainText,
    topBackgroundColor,
    topFontColor,
    components,
    bottomBackgroundColor,
    bottomFontColor,
    bottomMainText,
    bottomMainTitle,
  } = items;
  return (
    <MultiAccordionWrapper>
      {topMainTitle || topMainText ? (
        <TextWrapper
          backgroundColor={topBackgroundColor}
          fontColor={topFontColor}
        >
          {topMainTitle && <h2 className="title">{topMainTitle}</h2>}
          {topMainText && (
            <p
              className="text"
              dangerouslySetInnerHTML={{ __html: topMainText }}
            />
          )}
        </TextWrapper>
      ) : null}
      {components.map((item, index) => (
        <Accordion items={item.items} key={`accordion-item-${index}`} />
      ))}
      {bottomMainText || bottomMainTitle ? (
        <TextWrapper
          backgroundColor={bottomBackgroundColor}
          fontColor={bottomFontColor}
        >
          {bottomMainText && <h2 className="title">{bottomMainTitle}</h2>}
          {bottomMainTitle && (
            <p
              className="text"
              dangerouslySetInnerHTML={{ __html: bottomMainText }}
            />
          )}
        </TextWrapper>
      ) : null}
    </MultiAccordionWrapper>
  );
};

export default MultiAccordion;
