import noop from 'lodash/noop';
import { useEffect, useMemo, useRef } from 'react';

type CleanupFn = () => void;

export interface IAutoAPI {
  cleanup: CleanupFn;
  start(): CleanupFn;
  trigger(): void;
  stop(): void;
}

export default function useAuto(
  cb: () => void | Promise<void>,
  timeout?: number
): IAutoAPI {
  const timerID = useRef(undefined);
  const cbRef = useRef(cb);
  const triggerRef = useRef(noop);

  cbRef.current = cb;

  const auto = useMemo(() => {
    function cleanup() {
      clearTimeout(timerID.current);
      timerID.current = undefined;
    }

    function start() {
      if (!timeout) return;
      timerID.current = setTimeout(triggerRef.current, timeout);
      return cleanup;
    }

    triggerRef.current = () => {
      if (!timeout) return;
      const result = cbRef.current();
      if (typeof result === 'object') {
        result.then(start);
      } else {
        start();
      }
    };

    return {
      cleanup,
      start,
      trigger: triggerRef.current,
      stop: cleanup,
    };
  }, [timeout]);

  useEffect(
    function autoEffect() {
      return auto.start();
    },
    [auto]
  );

  return auto;
}
