import React, { useEffect } from 'react';
import { trackRecommendationsLoaded } from '@hotelplan/libs.tracking';
import { HorizontalBd4TravelRecommendation } from 'components/domain/bd4';
import { GeoRecsContainer } from 'components/domain/recommendations/components/GeoRecs';
import { MarketingRecommendationsSection } from 'components/domain/recommendations/components/MarketingRecs';
import { ProductRecommendationsSection } from 'components/domain/recommendations/components/ProductRecs';
import { ThemeRecommendationsSection } from 'components/domain/recommendations/components/ThemeRecs';
import { mapRecommendationsToTrackableData } from 'components/domain/recommendations/mappers';
import {
  DynamicComponentBd4TravelRecommendationGroupFragment,
  DynamicComponentMarketingRecommendationFragment,
  DynamicComponentThemeRecommendationFragment,
  DynamicComponentProductRecommendationFragment,
} from 'graphql/components/GetComponents.generated';
import { GeoRecommendationFragment } from 'graphql/recommendations/GeoRecommendation.generated';

interface IRecommendationsGroupProps {
  component:
    | DynamicComponentBd4TravelRecommendationGroupFragment
    | DynamicComponentMarketingRecommendationFragment
    | DynamicComponentThemeRecommendationFragment
    | DynamicComponentProductRecommendationFragment
    | GeoRecommendationFragment;
}

export default function RecommendationsGroup({
  component,
}: IRecommendationsGroupProps): React.ReactElement {
  useEffect(function trackRecommendations() {
    trackRecommendationsLoaded(
      mapRecommendationsToTrackableData(
        component.name,
        component.items,
        'tracking' in component ? component.tracking : undefined
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  switch (component.__typename) {
    case 'BD4TravelRecommendationGroup':
      return (
        <HorizontalBd4TravelRecommendation
          name={component.name}
          items={component.items}
        />
      );
    case 'MarketingRecommendation':
      return (
        <MarketingRecommendationsSection
          testId="marketing-recommendations-section"
          {...component}
        />
      );
    case 'ThemeRecommendation':
      return (
        <ThemeRecommendationsSection
          testId="theme-recommendations-section"
          {...component}
        />
      );
    case 'ProductRecommendation':
      return (
        <ProductRecommendationsSection
          testId="product-recommendations-section"
          {...component}
        />
      );
    case 'GeoRecommendation':
      if (!component.items.length) return null;
      return <GeoRecsContainer {...component} />;
  }
}
