import { TBottomDotsStyles } from '@hotelplan/components.common.responsive-media-gallery';

export const bottomControlsStyles: TBottomDotsStyles = {
  pagingDotsStyle: {
    width: '20px',
    height: '20px',
    borderRadius: '50%',
    background: 'linear-gradient(to bottom,#fff 0,#e6e6e6 100%)',
    fill: 'transparent',
  },
  pagingDotsContainerClassName: 'dots-container',
};
