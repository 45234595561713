import React, { PropsWithChildren } from 'react';
import ReactPlayer from 'react-player';
import styled from 'styled-components';
import type { INormalizedImage } from '@hotelplan/components.common.image';
import { Image } from '@hotelplan/components.common.image';

export interface IVideoMediaProps
  extends React.BaseHTMLAttributes<HTMLDivElement> {
  preview: INormalizedImage;
  resource: string;
  active?: boolean;
  playing?: boolean;
  muted?: boolean;
  lazy?: boolean;
  controls?: boolean;
  interactive?: boolean;
  onPlay?(): void;
  onEnded?(): void;
  className?: string;
}

const VideoMediaWrapper = styled.div({
  position: 'relative',
  width: '100%',
  height: '100%',
});

const PlayerWrapper = styled.div({
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: 0,
});

const PreviewImage = styled(Image)({
  width: '100%',
  height: '100%',
  pointerEvents: 'none',
  alignSelf: 'center',
  objectFit: 'cover',
});

export function VideoMedia(
  props: PropsWithChildren<IVideoMediaProps>
): React.ReactElement {
  const {
    className,
    active,
    playing = active,
    muted = !active,
    resource,
    preview,
    lazy,
    interactive = false,
    onPlay,
    onEnded,
    controls = false,
    ...otherDivProps
  } = props;

  return (
    <VideoMediaWrapper
      className={className}
      data-testid="video-media"
      {...otherDivProps}
    >
      <PreviewImage {...preview} lazy={lazy} />
      <PlayerWrapper style={{ pointerEvents: interactive ? 'all' : 'none' }}>
        <ReactPlayer
          url={resource}
          playing={active && playing}
          muted={muted}
          width="100%"
          height="100%"
          onPlay={onPlay}
          onEnded={onEnded}
          controls={controls}
          volume={0.3}
        />
      </PlayerWrapper>
    </VideoMediaWrapper>
  );
}
