import { IMediaGalleryItem } from '@hotelplan/components.common.responsive-media-gallery';
import { ThemeRecommendationItemFragment } from 'graphql/theme/ThemeRecommendationItem.generated';

export function mapThemeRecommendationItemToMediaGalleryItem(
  tItems: ThemeRecommendationItemFragment[]
): IMediaGalleryItem[] {
  return tItems.map(item => {
    return {
      __typename: 'ImageMediaItem',
      image: item.image,
      link: item.link,
    };
  });
}
