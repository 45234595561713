import styled from 'styled-components';
import { Button } from '@hotelplan/components.common.buttons';
import { Image } from '@hotelplan/components.common.image';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';

export const PageLeadWrapper = styled.div(
  sx2CssThemeFn({
    border: [null, '1px solid'],
    borderColor: [null, 'borderGray'],
    borderRadius: [null, 'default'],
    mt: [null, '20px'],
    mb: [null, '20px'],
    bg: 'white',
    '.content': {
      width: '100%',
    },
  })
);

export const PageLeadBlock = styled.div<{ addBorderBottom?: boolean }>(
  sx2CssThemeFn({
    display: 'flex',
    flexDirection: ['column-reverse', 'row'],
    pb: [null, 3],
  }),
  ({ addBorderBottom, theme: { colors } }) =>
    addBorderBottom
      ? {
          borderBottom: '1px solid',
          borderColor: colors.borderGray,
        }
      : null
);

export const PageLeadTextBlock = styled.div(
  sx2CssThemeFn({
    p: 3,
    maxWidth: [null, '250px'],
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    '.title': {
      lineHeight: '1.05',
      fontSize: [2, '26px'],
      color: 'secondary',
    },

    '.text': {
      color: 'textGray',
      lineHeight: ['1.2', '1.6'],
      fontSize: ['14px', 0],

      p: {
        mt: '10px',
        lineHeight: '1.5',
      },

      ul: {
        my: [3, 4],
        listStyle: 'disc',
        li: {
          mt: [2, 1],
          ml: 3,
        },
      },
      a: {
        color: 'primary',
        display: 'inline',
        '&:hover': {
          textDecoration: 'underline',
        },
      },
    },
  })
);

export const PageLeadMediaWrapper = styled.div(
  sx2CssThemeFn({
    position: 'relative',
    width: '100%',
    '.page-details': {
      display: 'flex',
      alignItems: 'start',
      justifyContent: ['center', 'space-between'],
    },
    '.productFeatures': {
      pt: [0, 2],
    },
  })
);

export const PageLeadHeroWrapper = styled.div(
  sx2CssThemeFn({
    position: 'relative',
    width: '100%',
    maxWidth: ['100%', '729px'],
    maxHeight: ['210px', '373px'],
    marginLeft: 'auto',
    overflow: 'hidden',
    borderLeft: ['none', '1px solid'],
    borderBottom: ['none', '1px solid'],
    borderRadius: ['none', '0 4px 0 4px'],
    borderColor: ['none', 'borderGray'],
    flexShrink: 0,
    ':after': {
      content: "''",
      display: 'block',
      width: '100%',
      paddingBottom: '51%',
      pointerEvents: 'none',
    },
  })
);

export const TopWrapper = styled.div(
  sx2CssThemeFn({
    display: 'flex',
    '.giataId': {
      ml: 'auto',
      fontSize: 0,
    },
  })
);

export const PageLeadHero = styled(Image)({
  position: 'absolute',
  display: 'block',
  objectFit: 'cover',
  width: '100%',
  height: '100%',
});

export const ShowMoreLessButton = styled(Button).attrs({
  variant: 'linkBtn',
})(
  sx2CssThemeFn({
    fontSize: 1,
    height: '14px',
    width: '10px',
    textAlign: 'left',
    mt: '-4px',
    lineHeight: 0,
    color: 'textGray',
    '&:hover': {
      color: 'primary',
    },
  })
);
