import { IMediaGalleryItem } from '@hotelplan/components.common.responsive-media-gallery';
import { MarketingRecommendationItem } from '@hotelplan/graphql.types';

export function mapMarketingRecommendationItemToMediaGalleryItem(
  mItems: MarketingRecommendationItem[]
): IMediaGalleryItem[] {
  return mItems.map(item => {
    const typename = item.preview.__typename;
    switch (typename) {
      case 'ImageMediaItem':
        return {
          __typename: typename,
          image: item.preview.image,
          title: item.title,
          mainText: item.mainText,
          link: item.link,
        };

      case 'VideoMediaItem':
        throw new Error('VideoMediaItem is not supported yet');
    }
  });
}
