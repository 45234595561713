import { useTranslation } from 'next-i18next';
import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';
import { Card } from 'components/domain/card';
import { RecommendationSectionTitle } from 'components/domain/recommendation-list';
import { RecommendationSlider } from 'components/domain/recommendations/components/Slider';
import { TBaseRecommendationItems } from 'components/domain/recommendations/types';
import { MarketingRecommendationFragment } from 'graphql/recommendations/MarketingRecommendation.generated';
import MarketingRecommendationItemSlideInfo from './MarketingRecommendationItemSlideInfo';
import { RecommendationWrap } from './MarketingRecs.styled';
import { mapMarketingRecommendationItemToMediaGalleryItem } from './MarketingRecs.utils';

const MarketingRecommendationsWrap = styled.div<{ isSingle?: boolean }>(
  ({ isSingle }) =>
    sx2CssThemeFn({
      mt: [3, 4],
      '.slider-control-centerleft, .slider-control-centerright': {
        display: isSingle ? 'none' : 'block',
      },
    })
);

interface ISectionProps extends MarketingRecommendationFragment {
  className?: string;
  testId?: string;
}

export default function MarketingRecommendationsSection({
  items,
  backgroundColor,
  titleFontColor,
  fontColor,
  className,
  testId,
  displayType = 'SWIPE',
  name,
}: ISectionProps): ReactElement {
  const [t] = useTranslation('common');
  const { mobile } = useDeviceType();

  const itemsResult = items.map(item => (
    <Card
      key={item.title}
      cardTitle={
        <>
          <span className="card-title">{item.title}</span>
          <span className="card-subtitle">{item.subtitle}</span>
        </>
      }
      image={item.preview.fullScreen}
      btnText={t('allOffers.btn')}
      btnIcon={{
        name: 'chevron-long-right',
        size: 'sm',
      }}
      link={item.link}
      cardText={item.mainText}
    />
  ));
  const mediaGalleryItems =
    mapMarketingRecommendationItemToMediaGalleryItem(items);

  return (
    <MarketingRecommendationsWrap
      className={className}
      style={{
        color: fontColor,
        backgroundColor,
      }}
      data-id={testId}
      isSingle={items.length === 1}
    >
      {name ? (
        <RecommendationSectionTitle
          style={{
            color: titleFontColor,
          }}
        >
          {name}
        </RecommendationSectionTitle>
      ) : null}

      {displayType === 'SWIPE' ? (
        <div>
          <RecommendationSlider items={mediaGalleryItems}>
            {slide => (
              <MarketingRecommendationItemSlideInfo
                item={mediaGalleryItems[slide] as TBaseRecommendationItems}
                slide={slide + 1}
                count={items.length}
                titleFontColor={titleFontColor}
                fontColor={fontColor}
              />
            )}
          </RecommendationSlider>
          {mobile && items.length === 1 ? (
            <MarketingRecommendationItemSlideInfo
              item={mediaGalleryItems[0] as TBaseRecommendationItems}
              count={items.length}
              titleFontColor={titleFontColor}
              fontColor={fontColor}
            />
          ) : null}
        </div>
      ) : (
        <RecommendationWrap>{itemsResult}</RecommendationWrap>
      )}
    </MarketingRecommendationsWrap>
  );
}
