import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';
import { RecommendationList } from 'components/domain/recommendation-list';

export const SlideDescription = styled.div(({ theme }) =>
  sx2CssThemeFn({
    position: 'absolute',
    top: ['82%', '30px'],
    right: '0px',
    display: 'flex',
    flexDirection: ['row', 'column'],
    alignItems: ['center', 'flex-start'],
    width: ['100%', '315px'],
    p: [null, 3],
    pointerEvents: 'none',
    boxShadow: [null, '-4px 4px 5px rgb(0 0 0 / 40%)'],
    backgroundColor: [null, 'rgba(255,255,255,0.9)'],
    '.card-subtitle': {
      color: 'secondary',
      fontFamily: '"Helvetica Neue",sans-serif',
      ml: 'auto',
      mb: 2,
    },
    '.prefix': {
      mr: 1,
    },
    '.price': {
      fontSize: '26px',
    },
    ':before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
    '.slide-number': {
      zIndex: 1,
      flex: '0 0 auto',
      pb: [null, '10px'],
      color: 'white',
      fontSize: '12px',
      ml: '10px',
      mt: 2,
    },
    '.slide-title': {
      zIndex: 1,
      fontSize: ['22px', '32px'],
      lineHeight: '1',
      mb: '10px',
      color: 'secondary',
    },
    '.slide-text': {
      ...theme.text.richText,
      fontSize: '13px',
      color: '#000',
      display: '-webkit-box',
      '-webkit-line-clamp': ['6', '6'],
      '-webkit-box-orient': 'vertical',
      overflow: 'hidden',
      mb: 2,
      maxHeight: '135px',
    },
    '.count-wrap': {
      display: 'flex',
      gap: '10px',
    },
    '.duration': {
      color: 'primary',
      textTransform: 'uppercase',
      fontFamily: '"Helvetica Neue",sans-serif',
      ml: 'auto',
    },
    '.button': {
      ...theme.buttons.allOffers,
      ml: 'auto',
    },
  })
);

export const RecommendationWrap = styled(RecommendationList)(
  sx2CssThemeFn({
    '.card': {
      borderLeft: '1px solid #999',
      borderRight: '1px solid #999',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    '.product-image': {
      height: ['210px', '170px'],
    },
    '.card-title': {
      fontSize: '22px',
      maxWidth: ['234px', '165px'],
    },
    '.card-subtitle': {
      maxWidth: [null, 'fit-content'],
      textAlign: ['end', 'start'],
      whiteSpace: 'nowrap',
      '.prefix': {
        mr: 1,
        fontSize: ['14px', 1],
      },
      '.price': {
        fontSize: '22px',
      },
    },
    '.card-top': {
      py: ['6px', 2],
    },
  })
);
