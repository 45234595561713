import { useRouter } from 'next/router';
import { ReactNode } from 'react';
import { MediaGallerySlider } from '@hotelplan/components.common.responsive-media-gallery';
import {
  getOpenMethod,
  getRouterLink,
} from '@hotelplan/libs.router-link-utils';
import { TBaseRecommendationItems } from 'components/domain/recommendations/types';
import { bottomControlsStyles } from './Slider.constants';

interface IRecommendationSliderProps {
  items?: TBaseRecommendationItems[];
  children?: (slide: number) => ReactNode;
}

const RecommendationSlider: React.FC<IRecommendationSliderProps> = ({
  items,
  children,
}) => {
  const { push } = useRouter();

  return (
    <MediaGallerySlider<TBaseRecommendationItems>
      items={items}
      isAutoPlay={items.length > 1}
      bottomControl={{
        show: items.length > 1,
        styles: bottomControlsStyles,
      }}
      onSlideClick={item => {
        const { href, as } = getRouterLink(item.link);
        const openMethod = getOpenMethod(item.link);
        if (openMethod === '_blank') {
          window.open(href as string, openMethod);
        } else {
          push(href, as, {
            shallow: false,
            scroll: true,
          });
        }
      }}
    >
      {children}
    </MediaGallerySlider>
  );
};

export default RecommendationSlider;
