import React from 'react';
import { LinksList } from '@hotelplan/components.common.links-list';
import { LinkListFragment } from 'graphql/fragments/LinkList.generated';
import { LinkListWrapper } from './LinksListView.styles';
import { getMenuLinks } from './LinksListView.utils';

interface ILinksListComponentProps {
  component: LinkListFragment;
  index: number;
}

const LinksListView: React.FC<ILinksListComponentProps> = ({
  component: { links },
  index,
}) => {
  const menuLinks = getMenuLinks(links);
  return (
    <LinkListWrapper>
      <LinksList links={menuLinks} data-test={`links-list-${index}`} />
    </LinkListWrapper>
  );
};

export default LinksListView;
