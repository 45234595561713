import React from 'react';
import styled from 'styled-components';
import {
  Accordion as BaseAccordion,
  AccordionItem,
  AccordionMode,
} from '@hotelplan/components.common.accordion';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';
import { TextComponentFragment } from 'graphql/fragments/TextComponent.generated';

const AccordionWrapper = styled.div(({ theme }) =>
  sx2CssThemeFn({
    fontSize: [null, 3],
    mx: [null, 'auto'],
    px: [2, 0],
    py: [3, 0],
    '.accordionItem': {
      mb: '10px',
    },
    '.accordionButton': {
      pl: ['35px', 6],
      pr: [0, '20px'],
      color: 'primary',
      width: '100%',
      textAlign: 'start',
      height: ['40px', '42px'],
      fontSize: [null, '18px'],
      fontWeight: '600',
      border: '1px solid #ccc',
      borderLeft: ['none', '1px solid #ccc'],
      borderRight: ['none', '1px solid #ccc'],
      borderRadius: [null, '5px 0 0 5px'],
      position: 'relative',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      backgroundColor: 'white',
      '&:hover': {
        backgroundColor: '#eee',
      },

      '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        top: '18px',
        left: ['10px', '14px'],
        width: '16px',
        height: '3px',
        backgroundColor: '#aaaaab',
        borderRadius: '2px',
      },
      '&:after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        top: '18px',
        left: ['10px', '14px'],
        width: '16px',
        height: '3px',
        backgroundColor: '#aaaaab',
        borderRadius: '2px',
        transform: 'rotate(90deg)',
      },
      '.chevron': {
        display: 'none',
      },
    },
    '.accordionButton.active': {
      borderRadius: [null, '5px 0 0 0'],
      borderBottom: '1px solid',
      borderColor: '#ccc',
      '&:after': {
        display: 'none',
      },
      '&:hover': {
        backgroundColor: '#fff',
      },
    },
    mb: [5, '50px'],
    '.accordionContent': {
      px: [1, '10px'],
      backgroundColor: '#f7f7f7',
      border: '1px solid',
      borderColor: '#ccc',
      borderLeft: ['none', '1px solid #ccc'],
      borderRight: ['none', '1px solid #ccc'],
      borderRadius: '0 0 0 5px',
    },
    '.accordion-rich-text': {
      padding: theme.space[3],
      fontSize: ['14px', 0],
      td: {
        width: ['50%', '220px'],
        pb: [null, 1],
        whiteSpace: 'nowrap',
      },
      ...theme.text.richText,
    },
    '.accordionItem .accordionButtonWrap': {
      p: 0,
    },
  })
);

interface IAccordionProps {
  items: Array<TextComponentFragment>;
}

function Accordion({ items }: IAccordionProps): React.ReactElement {
  return (
    <AccordionWrapper className="accordion">
      <BaseAccordion mode={AccordionMode.MULTIPLE}>
        {items?.map(item => {
          return (
            <AccordionItem key={item.title} title={item.title}>
              <div
                className="accordion-rich-text"
                dangerouslySetInnerHTML={{ __html: item.text }}
              />
            </AccordionItem>
          );
        })}
      </BaseAccordion>
    </AccordionWrapper>
  );
}

export default Accordion;
