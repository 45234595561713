import type { ICarouselState } from './createGetMoveState';
import { createGetSliderState } from './createGetSliderState';

interface ISlideAnimateState {
  isActive: boolean;
  from: number;
  to: number;
}

interface IGetSliderStateFn {
  (state: ICarouselState, i: number, mx?: number): ISlideAnimateState;
}

export function createGetSlideAnimateState(
  count: number,
  width: number
): IGetSliderStateFn {
  const getState = createGetSliderState(count, width);

  return (state, i, mx = 0): ISlideAnimateState => {
    const { dir, baseX, isActive, isCurrentSlide, isPrevSlide } = getState(
      state,
      i
    );

    // invisible slides should not intersect viewport area
    // they should stay at their previous side
    const inertiaTo = (i - state.prev - dir) * width;

    const from = isCurrentSlide
      ? dir * width + mx
      : isPrevSlide
      ? mx
      : inertiaTo;

    const to = isPrevSlide ? -dir * width : isActive ? baseX : inertiaTo;

    return {
      isActive,
      from,
      to,
    };
  };
}
