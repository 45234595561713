import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';
import { GeoChild } from 'components/domain/geo';

export const GeoRecsWrapper = styled.div(
  sx2CssThemeFn({
    mt: [3, 4],
    '.formatted-offer-info': {
      fontSize: '14px',
      display: 'flex',
      flexDirection: 'column',
      pb: 3,
    },
  })
);

export const GeoRecsLargeWrapper = styled.div(({ theme: { media } }) => ({
  [media.tablet]: {
    '.image': {
      minWidth: '731px',
      minHeight: '375px',
      img: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
      },
    },
  },
}));

export const GeoChildWrapper = styled(GeoChild)<{ index: number }>(
  ({ theme: { media } }) =>
    sx2CssThemeFn({
      [media.tablet]: {
        '.wrap': {
          border: '1px solid',
          borderColor: 'borderGray',
        },
      },
      '.offer-type': {
        textTransform: 'uppercase',
      },
    }),
  ({ index, theme: { media } }) =>
    index
      ? sx2CssThemeFn({
          [media.tablet]: {
            '.wrap': {
              borderTop: 'none',
            },
          },
        })
      : null
);
