import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';

export const LinkListWrapper = styled.div(
  sx2CssThemeFn(({ theme }) => ({
    pt: [null, 4],
    pb: [3, 4],
    '.links-list': {
      width: '100%',
      justifyContent: 'center',
      border: 'none',
    },
    '.list-item a': {
      ...theme.buttons.primary,
      position: 'relative',
      color: 'white',
      pr: ['30px', '30px'],
      fontSize: 1,
      '&:after': {
        content: '""',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        right: '5px',
        display: 'block',
        width: '15px',
        height: '15px',
        backgroundImage: 'url("/images/svg/chevron-long-right-white.svg")',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      },
    },
  }))
);
