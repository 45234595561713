import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';
import { RecommendationList } from 'components/domain/recommendation-list';

export const RecommendationWrap = styled(RecommendationList)(
  sx2CssThemeFn({
    gridTemplateColumns: ['1fr 1fr', `repeat(3, 315px)`],
    '.content': {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    '.card': {
      borderLeft: '1px solid #999',
      borderRight: '1px solid #999',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    '.product-image': {
      height: ['100px', '170px'],
    },
    '.card-title': {
      fontSize: '22px',
      maxWidth: ['154px', '290px'],
      mr: [null, -1],
    },
    '.card-subtitle': {
      textAlign: ['end', 'start'],
      whiteSpace: 'nowrap',
      '.prefix': {
        mr: 1,
      },
    },
    '.card-top': {
      py: ['2px', 2],
    },
    '.button': {
      position: ['relative', 'absolute'],
      ml: ['auto', 0],
    },
  })
);

export const SlideDescription = styled.div<{ isSingle?: boolean }>(
  ({ theme, isSingle }) =>
    sx2CssThemeFn({
      position: [isSingle ? 'static' : 'absolute', 'absolute'],
      top: ['82%', '30px'],
      right: '0px',
      display: 'flex',
      flexDirection: [isSingle ? 'column' : 'row', 'column'],
      alignItems: ['center', 'flex-start'],
      width: ['100%', '315px'],
      p: [null, 3],
      pointerEvents: 'none',
      boxShadow: [null, '-4px 4px 5px rgb(0 0 0 / 40%)'],
      backgroundColor: [null, 'rgba(255,255,255,0.9)'],
      ':before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
      },
      '.slide-number': {
        zIndex: 1,
        flex: '0 0 auto',
        pb: [null, '10px'],
        color: 'white',
        fontSize: '12px',
        ml: '10px',
        mt: 2,
      },
      '.slide-title': {
        zIndex: 1,
        fontSize: ['22px', '32px'],
        lineHeight: '1',
        mb: '10px',
      },
      '.slide-text': {
        fontSize: '13px',
        lineHeight: '1.5',
        mb: isSingle ? 0 : '10px',
        padding: isSingle ? 3 : null,
        pb: isSingle ? 1 : null,
        p: {
          mb: '10px',
        },
      },
      '.count-wrap': {
        display: 'flex',
        gap: '10px',
      },
      '.button': {
        ...theme.buttons.allOffers,
        ml: isSingle ? null : 'auto',
        mb: isSingle ? 4 : null,
      },
    })
);
