import styled from 'styled-components';
import { MediaGallery } from '@hotelplan/components.common.media-gallery';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';

const CommonMediaGallery = styled(MediaGallery).attrs({
  autoplay: true,
})(
  sx2CssThemeFn({
    position: 'relative',
    '.slider, .slider-slide, .slider-frame, .slider-slide .image': {
      width: '100%!important',
      height: ['210px', '373px'],
    },
    '.slider-slide': {
      height: 'auto!important',
      maxHeight: '100%',
    },

    '.slide-info': {
      position: 'absolute',
      bottom: 1,
      left: 0,
      background: [null, 'white'],
      width: '100%',
      opacity: [null, 0.5],
      fontSize: 0,
      py: 2,
      m: 0,
      transition: 'opacity 0.3s ease-out',
      flexDirection: 'row-reverse',

      '.slide-title': {
        width: '100%',
        textAlign: 'center',
        color: ['transparent', 'primary'],
      },
      '.slide-number': {
        px: 2,
        color: ['white', 'primary'],
      },
    },

    '&:hover .slide-info': {
      opacity: [null, 0.8],
    },
    '.icon': {
      display: 'block',
    },
  })
);

export default CommonMediaGallery;
