import dynamic from 'next/dynamic';
import React, { useMemo, useState } from 'react';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import ProductFeatures from 'components/core/product/ProductFeatures';
import ProductRating from 'components/core/product/ProductRating';
import ProductTripAdvisorInfo from 'components/core/product/ProductTripAdvisorInfo';
import { Disrupter, EDisrupterPlacement } from 'components/domain/disrupter';
import {
  PageLeadBlock,
  PageLeadHero,
  PageLeadHeroWrapper,
  PageLeadMediaWrapper,
  PageLeadTextBlock,
  PageLeadWrapper,
  ShowMoreLessButton,
  TopWrapper,
} from './PageLead.styled';
import { IPageLeadProps } from './PageLead.types';

const SiteTools = dynamic<{ wishlistIcon?: boolean }>(
  () => import('./PageLeadSiteTools'),
  { ssr: false }
);

const ProductBreadcrumb = dynamic(
  () => import('components/core/product/ProductBreadcrumb'),
  { ssr: false }
);

const COMMON_WORD_LENGTH = 10;
const MAX_TEXT_LENGTH = 510;

const PageLead: React.FC<IPageLeadProps> = ({
  rating,
  title,
  text,
  breadcrumbs,
  tripadvisor,
  hero,
  addBorderBottom,
  children,
  mediaGallery,
  colorTitle,
  colorText,
  offer,
  featuresSummary,
  isHeroSiteToolsEnabled,
  wishlistIcon,
  giataId,
  maxTextLength = MAX_TEXT_LENGTH,
  disrupterObject,
  className,
}: IPageLeadProps) => {
  const { mobile } = useDeviceType();
  const [isMore, toggleMore] = useState(false);

  const shownWordsCount = maxTextLength / COMMON_WORD_LENGTH;
  const textList = text?.split(' ');

  const isShowMoreButton = useMemo(
    () => textList?.length > shownWordsCount,
    [textList?.length, shownWordsCount]
  );

  const basicText = useMemo(
    () =>
      isShowMoreButton ? textList.slice(0, shownWordsCount).join(' ') : text,
    [isShowMoreButton, textList, shownWordsCount, text]
  );

  return (
    <PageLeadWrapper>
      <PageLeadBlock addBorderBottom={addBorderBottom} className={className}>
        <PageLeadTextBlock>
          <TopWrapper>
            {!!rating && <ProductRating rating={rating} />}
            {giataId && <div className="giataId">{giataId}</div>}
          </TopWrapper>
          <h1
            dangerouslySetInnerHTML={{ __html: title as string }}
            className="title"
            style={{
              color: colorTitle,
            }}
          />
          {breadcrumbs?.length && (
            <ProductBreadcrumb items={breadcrumbs} delimiter="|" />
          )}
          {tripadvisor?.rating > 0 && (
            <ProductTripAdvisorInfo
              taRating={tripadvisor.rating}
              taReviews={tripadvisor.reviews || 0}
            />
          )}
          {text && (
            <>
              <div
                dangerouslySetInnerHTML={{
                  __html: isMore || mobile ? text : (basicText as string),
                }}
                className="text"
                style={{
                  color: colorText,
                }}
              />
              {!mobile && isShowMoreButton ? (
                <ShowMoreLessButton onClick={() => toggleMore(!isMore)}>
                  {isMore ? null : '...'}
                </ShowMoreLessButton>
              ) : null}
            </>
          )}
          {offer}
        </PageLeadTextBlock>

        <PageLeadMediaWrapper>
          <PageLeadHeroWrapper>
            {disrupterObject ? (
              <Disrupter
                {...disrupterObject}
                placement={EDisrupterPlacement.LEAD}
              />
            ) : null}
            {hero ? <PageLeadHero {...hero} /> : mediaGallery}
          </PageLeadHeroWrapper>
          <div className="page-details">
            {featuresSummary && (
              <ProductFeatures featuresSummary={featuresSummary} />
            )}
            {!isHeroSiteToolsEnabled ? null : (
              <SiteTools wishlistIcon={wishlistIcon} />
            )}
          </div>
        </PageLeadMediaWrapper>
      </PageLeadBlock>
      <div className="content">{children}</div>
    </PageLeadWrapper>
  );
};

export default PageLead;
