export interface ICarouselState {
  curr: number;
  prev: number;
  dir: number;
}

interface IMoveState {
  dir: number; // "1" slide left, "-1" slide right, "0" - idle
  targetIndex: number; // semi-visible target slide index, "-1" - in idle
}

interface IGetMoveStateFn {
  (s: ICarouselState, mx?: number): IMoveState;
}

export function createGetMoveState(count: number): IGetMoveStateFn {
  return (s, mx = 0) => {
    const dir = mx === 0 ? s.dir : mx < 0 ? 1 : -1;
    const targetIndex = mx !== 0 ? (s.curr + dir + count) % count : -1;

    return {
      dir,
      targetIndex,
    };
  };
}
