import { IMenuLink } from '@hotelplan/components.common.menu';
import {
  getOpenMethod,
  getRouterLink,
} from '@hotelplan/libs.router-link-utils';
import { LinkListItemFragment } from 'graphql/fragments/LinkList.generated';

export const getMenuLinks = (links: LinkListItemFragment[]): IMenuLink[] => {
  return links.map(link => {
    const routerLink = link.link ? getRouterLink(link.link) : null;
    const linkTarget = getOpenMethod(link.link);

    return {
      link: routerLink,
      label: link.title,
      target: linkTarget,
    };
  });
};
