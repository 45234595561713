import React, {
  PropsWithChildren,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { animated, useSprings } from 'react-spring';
import { useDrag } from 'react-use-gesture';
import useMeasure from 'react-use-measure';
import styled from 'styled-components';
import { Icon } from '@hotelplan/components.common.icon';
import { useIsAppActive, useOnScreen } from '@hotelplan/libs.hooks-dom';
import { createGetMoveState, ICarouselState } from './createGetMoveState';
import { createGetSlideAnimateState } from './createGetSlideAnimateState';
import { createGetSliderState } from './createGetSliderState';
import useAuto, { IAutoAPI } from './useAuto';

const JustCarouselWrapper = styled.section<{ ratio: number | false }>(
  {
    position: 'relative',
    width: '100%',
    overflow: 'hidden',
  },
  ({ ratio }) => ({
    paddingTop: `${100 * Number(ratio)}%`,
  })
);

const CarouselSlider = styled(animated.ul).attrs({
  className: `slider`,
})({
  position: 'absolute',
  height: '100%',
  width: '100%',
  top: 0,
  bottom: 0,
});

const CarouselSlide = styled(animated.li).attrs({
  className: `slide`,
})({
  position: 'absolute',
  userSelect: 'none',
  height: '100%',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  top: 0,
  boxShadow:
    '0 62.5px 125px -25px rgba(50, 50, 73, 0.5), 0 37.5px 75px -37.5px rgba(0, 0, 0, 0.6)',
  img: {
    pointerEvents: 'none',
  },
  pointerEvents: 'none',
});

const SlideIcon = styled(Icon).attrs({
  name: `chevron-big-left`,
})({
  position: 'absolute',
  width: '30px',
  height: '97px',
  top: '50%',
  color: 'white',
  filter: 'drop-shadow(1px 2px 2px rgba(0, 0, 0, 0.5))',
  cursor: 'pointer',
  transition: 'filter',
  zIndex: 3,
  ':active': {
    filter: 'drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.5))',
  },
});

const SlideLeftIcon = styled(SlideIcon).attrs({
  className: `slide-left`,
  testId: `left`,
})({
  transform: 'translateY(-50%)',
  left: '40px',
  ':hover': {
    transform: 'translateY(-50%) scale(1.1)',
  },
});

const SlideRightIcon = styled(SlideIcon).attrs({
  className: `slide-right`,
  testId: `right`,
})({
  transform: 'rotateY(180deg) translateY(-50%)',
  right: '40px',
  ':hover': {
    transform: 'rotateY(180deg) translateY(-50%) scale(1.1)',
  },
});

interface IItemState {
  visible: boolean;
}

export interface IJustCarouselProps<TItem = unknown> {
  className?: string;
  items: TItem[];
  ratio?: number | false;
  scaling?: boolean;
  sliding?: boolean;
  dragging?: boolean;
  chevrons?: boolean;
  autoSlide?: number;
  renderItem(
    item: TItem,
    index: number,
    state: IItemState,
    auto: IAutoAPI
  ): React.ReactElement | undefined | null;
}

interface ISpringResult<TState> {
  immediate?: boolean;
  from?: TState;
  to?: TState | (TState & ISpringResult<TState>)[];
}

interface IStylesState extends ISpringResult<IStylesState> {
  visible?: boolean;
  o?: number;
  x?: number;
  sc?: number;
}

export function JustCarousel<TItem = unknown>(
  props: PropsWithChildren<IJustCarouselProps<TItem>>
): React.ReactElement {
  const {
    className,
    ratio = 0.5,
    scaling = true,
    chevrons = true,
    sliding = true,
    items,
    renderItem,
    autoSlide,
  } = props;

  const dragging = sliding && props.dragging !== false;
  const count = items.length;
  const wrapperRef = useRef<HTMLElement | SVGElement>(null);
  const [measureRef, { width }] = useMeasure();
  const { isOnScreen } = useOnScreen(wrapperRef);
  const isAppActive = useIsAppActive();

  const ref = (node: HTMLElement | SVGElement | null) => {
    node && measureRef(node);
    wrapperRef.current = node;
  };

  const shift = useRef({ mx: 0, sc: 1, scTarget: -1 });

  const [state, setState] = useState<ICarouselState>({
    curr: 0,
    prev: 0,
    dir: 0,
  });

  const slideLeft = useCallback(() => {
    shift.current.scTarget = -1;
    shift.current.mx = 0;
    setState(prevState => ({
      prev: prevState.curr,
      curr: (prevState.curr - 1 + count) % count,
      dir: -1,
    }));
  }, [count]);

  const slideRight = useCallback(() => {
    shift.current.scTarget = -1;
    shift.current.mx = 0;
    setState(prevState => ({
      prev: prevState.curr,
      curr: (prevState.curr + 1) % count,
      dir: 1,
    }));
  }, [count]);

  const auto = useAuto(slideRight, count > 1 ? autoSlide : undefined);

  const getMoveState = useMemo(() => createGetMoveState(count), [count]);

  const getSlideState = useMemo(
    () => createGetSliderState(count, width),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [count, width]
  );

  const getSlideAnimateState = useMemo(
    () => createGetSlideAnimateState(count, width),
    [count, width]
  );

  const [springs, api] = useSprings(
    count,
    sliding
      ? (i): IStylesState => {
          const { mx, sc: memSc, scTarget } = shift.current;
          const {
            from: fromX,
            to,
            isActive,
          } = getSlideAnimateState(state, i, mx);
          const sc = scaling && scTarget === i ? memSc : 1;

          // from is set only for slide buttons
          // target slide is already in place on dragging events
          const from =
            mx === 0
              ? { visible: true, x: fromX, sc, immediate: true }
              : undefined;

          return isActive
            ? {
                from,
                to: [from, { x: to, sc: 1 }],
              }
            : { visible: false, x: to, sc, immediate: true };
        }
      : (i): IStylesState => {
          return {
            from: { visible: state.prev === i, o: state.prev === i ? 1 : 0 },
            to: { visible: state.curr === i, o: state.curr === i ? 1 : 0 },
          };
        },
    [width, state]
  );

  const bind = useDrag(
    ({ down, movement: [mx], distance, cancel, first, canceled }) => {
      if (canceled) return;
      const { dir, targetIndex } = getMoveState(state, mx);

      if (first) {
        auto.stop();
      }

      if (down && distance > width / 3) {
        const currentX = api.stop().current[state.curr].get().x;
        // save shift x axis movement
        shift.current.mx = currentX;
        cancel();
        setState(prevState => ({
          prev: prevState.curr,
          curr: targetIndex,
          dir,
        }));
        auto.start();
        return;
      }

      api.start(i => {
        const { isActive, isCurrentSlide, baseX } = getSlideState(state, i, mx);

        const sc =
          scaling && isCurrentSlide && down ? 1 - distance / width / 5 : 1;

        // remember scale
        if (sc < 1) {
          shift.current.sc = sc;
          shift.current.scTarget = i;
        }

        return {
          visible: isActive,
          x: down ? baseX + mx : baseX,
          sc,
          immediate: first,
          // immediate: true,
        };
      });
    },
    {
      axis: 'x',
      useTouch: true,
      enabled: count > 1 && dragging,
    }
  );

  return (
    <JustCarouselWrapper
      className={`carousel-wrapper ${className}`}
      ref={ref}
      ratio={ratio}
    >
      <CarouselSlider {...bind()}>
        {springs.map(({ visible, x, o, sc }, index) => (
          <CarouselSlide
            key={index}
            style={{
              display:
                sliding && visible.get() === false && index !== state.curr
                  ? 'none'
                  : 'flex',
              left: sliding ? x?.to(v => `${v}px`) : 0,
              opacity: sliding ? undefined : o?.to(v => `${v}`),
              transform: scaling ? sc?.to(v => `scale(${v})`) : undefined,
              zIndex: index === state.curr ? 1 : 0,
            }}
          >
            {renderItem(
              items[index],
              index,
              {
                visible: isAppActive && isOnScreen && index === state.curr,
              },
              auto
            )}
          </CarouselSlide>
        ))}
      </CarouselSlider>
      {chevrons && count > 1 ? (
        <>
          <SlideLeftIcon onClick={slideLeft} aria-label={`Previous slide`} />
          <SlideRightIcon onClick={slideRight} aria-label={`Next slide`} />
        </>
      ) : null}
    </JustCarouselWrapper>
  );
}
