import React from 'react';

interface IOfferInfo {
  destinationName?: string;
  numberOfNightsInfo?: string;
  travelTypeInfo?: string;
  example?: string;
}

export function getFormattedOfferInfo(offerInfo: IOfferInfo): React.ReactNode {
  const { destinationName, numberOfNightsInfo, travelTypeInfo, example } =
    offerInfo;

  const destination =
    example && destinationName ? `${example} ${destinationName},` : ``;

  return (
    <div className="formatted-offer-info">
      <span>{`${destination} ${numberOfNightsInfo}`}</span>
      <span>{travelTypeInfo}</span>
    </div>
  );
}
