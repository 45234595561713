import { useTranslation } from 'next-i18next';
import React from 'react';
import { Button } from '@hotelplan/components.common.buttons';
import { TravelType } from '@hotelplan/graphql.types';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { formatPrice } from '@hotelplan/libs.utils';
import { ThemeRecommendationItemFragment } from 'graphql/theme/ThemeRecommendationItem.generated';
import { mapDurationToWeeks } from 'libs';
import { SlideDescription } from './ThemeRecs.styled';

interface ISlideInfoProps {
  item?: ThemeRecommendationItemFragment;
  slide: number;
  count: number;
}

export default function ThemeRecommendationItemSlideInfo({
  item,
  slide,
  count,
}: ISlideInfoProps): React.ReactElement {
  const [t] = useTranslation(['common', 'search']);
  const { mobile } = useDeviceType();

  const {
    name,
    duration,
    price,
    themeItemText,
    totalProductCount,
    travelType,
  } = item;

  return (
    <SlideDescription className={`slide-info`}>
      <>
        {name && !mobile && <h3 className="slide-title">{name}</h3>}
        {!mobile && (
          <div
            className="slide-text"
            dangerouslySetInnerHTML={{ __html: themeItemText }}
          />
        )}
        {mobile && (
          <div className={`slide-number`}>
            {slide} | {count}
          </div>
        )}
        {duration && (
          <p className="duration">
            {mapDurationToWeeks(duration, t)}{' '}
            {travelType === TravelType.Package
              ? t('search:travel.type.package')
              : t('search:travel.type.hotel')}
          </p>
        )}
        {price && (
          <p className="card-subtitle">
            <span className="prefix">
              {t('priceFrom')} {price.currency}
            </span>
            {formatPrice(price, { currency: false })}
          </p>
        )}
        <Button
          className="button"
          icon={{
            name: 'chevron-long-right',
            size: 'xs',
          }}
        >
          {t(mobile ? 'common:allOffers' : 'children.offer.cta.desktop', {
            count: totalProductCount,
          })}
        </Button>
      </>
    </SlideDescription>
  );
}
