import React from 'react';
import styled from 'styled-components';
import type { INormalizedImage } from '@hotelplan/components.common.image';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';
import {
  IJustCarouselProps,
  JustCarousel,
} from 'components/domain/just-carousel';
import { ImageMedia } from 'components/domain/media/Image';
import { VideoMedia } from 'components/domain/media/Video';

export type ImageFragment = {
  __typename?: 'ImageMediaItem';
  title: string;
  image: INormalizedImage;
};

export type VideoFragment = {
  __typename?: 'VideoMediaItem';
  title: string;
  resource: string;
  preview: INormalizedImage;
};

interface IMediaGalleryStyles {
  flagline?: string;
  mainTitle?: string;
  fontColor?: string;
}

type IHeroMediaGalleryProps = Omit<
  IJustCarouselProps<ImageFragment | VideoFragment>,
  'renderItem'
> &
  IMediaGalleryStyles;

const HeroCarousel = styled(JustCarousel)(() =>
  sx2CssThemeFn({
    height: ['210px', '373px'],
    '.image-media': {
      height: ['210px', '373px'],
    },
  })
) as React.FC<IJustCarouselProps<ImageFragment | VideoFragment>>;

const ImageMediaWrapper = styled.div({
  width: '100%',
  height: '100%',
  position: 'relative',
});

const HeroMediaGalleryWrapper = styled.div({
  position: 'relative',
});

export const HeroMediaGallery: React.FC<IHeroMediaGalleryProps> = props => {
  const {
    items,
    ratio = 0.5,
    sliding = false,
    chevrons = false,
    autoSlide = 7000,
    ...carouselProps
  } = props;

  return (
    <HeroMediaGalleryWrapper>
      <HeroCarousel
        sliding={sliding}
        ratio={ratio}
        chevrons={chevrons}
        autoSlide={autoSlide}
        {...carouselProps}
        items={items.filter(item =>
          ['ImageMediaItem', 'VideoMediaItem'].includes(item.__typename)
        )}
        renderItem={(item, index, { visible }, auto) => {
          switch (item.__typename) {
            case 'ImageMediaItem':
              return (
                <ImageMediaWrapper>
                  <ImageMedia
                    key={index}
                    {...item.image}
                    lazy={index !== 0}
                    className="image-media"
                  />
                </ImageMediaWrapper>
              );
            case 'VideoMediaItem':
              return (
                <VideoMedia
                  key={`video-${index}`}
                  active={visible}
                  preview={item.preview}
                  resource={item.resource}
                  lazy={index !== 0}
                  onPlay={auto.stop}
                  onEnded={auto.trigger}
                />
              );
            default:
              return null;
          }
        }}
      />
    </HeroMediaGalleryWrapper>
  );
};
