import { useTranslation } from 'next-i18next';
import React from 'react';
import { Button } from '@hotelplan/components.common.buttons';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { TBaseRecommendationItems } from 'components/domain/recommendations/types';
import { SlideDescription } from './MarketingRecs.styled';

interface ISlideInfoProps {
  item?: TBaseRecommendationItems;
  slide?: number;
  count: number;
  titleFontColor?: string;
  fontColor?: string;
}

export default function MarketingRecommendationItemSlideInfo({
  item,
  slide,
  count,
  titleFontColor,
  fontColor,
}: ISlideInfoProps): React.ReactElement {
  const [t, locale] = useTranslation('common');
  const { mobile } = useDeviceType();

  const { mainText, title } = item;

  const ctaBtnMobile =
    locale.language === 'de'
      ? `${title} ${t('offers.btn')}`
      : `${t('offers.btn')} ${title}`;

  return (
    <SlideDescription className={`slide-info`} isSingle={count === 1}>
      {title && !mobile && (
        <h3
          className="slide-title"
          style={{
            color: titleFontColor,
          }}
        >
          {title}
        </h3>
      )}

      {!mobile || count === 1 ? (
        <div
          className="slide-text"
          dangerouslySetInnerHTML={{ __html: mainText }}
          style={{
            color: fontColor,
          }}
        />
      ) : null}

      {mobile && count > 1 ? (
        <div className={`slide-number`}>
          {slide} | {count}
        </div>
      ) : null}
      <Button
        className="button"
        icon={{
          name: 'chevron-long-right',
          size: 'xs',
        }}
      >
        {ctaBtnMobile}
      </Button>
    </SlideDescription>
  );
}
